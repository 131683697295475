<template>
	<div class="container container-product">
		<div class="row row-header">
			<div class="head prdct-container sandwich-container w-100" v-if="type == 'sandwich'">
				<h3>{{ sandwichConfiguration.title }}</h3>
				<p class="product-tab" v-html="sandwichConfiguration.description"></p>
        <p class="product-tab" v-if="showSandwichDeadlineMessage" v-html="sandwichConfiguration.msgAfterDeadline"></p>
				<!-- <p v-if="display_alert_sandwich" v-html="settingData.alert_message"></p> -->
				<div class="form-group checkout-form checkout-form-time mt10">
					<select
						:class="{ 'is-invalid': errors.has('selectedPickupDate') }"
						name="selectedPickupDate"
						class="form-control tenzar-form-control"
						v-model="selectedPickupDate"
					>
						<option :value="null">{{ $t('Checkout.FORM_FIELD.SANDWICH_PICKUP_DATE') }}</option>
						<option
							v-for="(value, name) in pickupDates"
							:key="name"
							:value="value.date"
						>
							{{ value.date_detail }}
						</option>
					</select>
				</div>
				<div class="form-group checkout-form checkout-form-time">
					<select
						:class="{ 'is-invalid': errors.has('selectedPickupTime') }"
						name="selectedPickupTime"
						class="form-control tenzar-form-control"
						v-model="selectedPickupTime"
					>
						<option :value="null">{{ $t('Checkout.FORM_FIELD.SANDWICH_PICKUP_TIME') }}</option>
						<option
							v-for="value in pickupTimes"
							:key="value.id"
							:value="value.start_time"
						>
							{{ value.start_time.slice(0, 5) }}
						</option>
					</select>
				</div>
				<div id="overlay" class="overlay-deadline-sandwich">
					<button
						id="hidemsg"
						@click="messageHide('sandwich')"
						class="button-element hide-msg-button"
					>
						&times;
					</button>
					<div id="text">{{ sandwichConfiguration.msgAfterDeadline }}</div>
				</div>
			</div>
			<div class="head prdct-container snack-container" v-if="type == 'snack'">
				<h3>{{ snackConfiguration.title }}</h3>
				<p v-html="snackConfiguration.description"></p>
				<div id="overlay" class="overlay-deadline-snack">
					<button
						id="hidemsg"
						@click="messageHide('snack')"
						class="button-element hide-msg-button"
					>
						&times;
					</button>
					<div id="text">{{ snackConfiguration.snackMsgAfterDeadline }}</div>
				</div>
				<div id="overlay" class="overlay-snack">
					<button
						id="hidemsg"
						@click="show_snacks_overlay_second"
						class="button-element hide-msg-button close-btn-snacks"
					>
						&times;
					</button>
					<div id="text" class="overlay-div">
						{{ settingData.snacks_order_not_allow_message }}
						<table v-if="snacksTimings">
							<tr v-for="(tabData, index) in snacksTimings" :key="index">
								<td class="t-left w50">{{ tabData.day }}</td>
								<td class="t-right w50">{{ tabData.time }}</td>
							</tr>
						</table>
					</div>
				</div>
				<div id="overlay" class="overlay-snack-second">
					<button
						id="hidemsg"
						@click="hide_snacks_overlay_second"
						class="button-element hide-msg-button"
					>
						&times;
					</button>
					<div id="text" class="overlay-div snack-div">
            <div style="" class="middle-txt">
              <div class="snack-txt">
                  {{ settingData.snacks_message_before_redirect }}  
              </div>
              <div class="loading">
                  <img  src="@/assets/images/loading-img.gif" alt="product-img" class="loader" />
              </div>
            </div>
					</div>
				</div>
			</div>
			<div class="col-12 prdct-menu" v-if="tabs.length > 0">
				<div class="testimonial-group">
				<ul class="product-filters-container text-center">
					<li class="tbdata" v-for="(tabData, index) in tabs" :key="index">
					<a
						:href="'#' + tabData.containerName"
						class="category-btn"
						:class="{ active: tabData.isActive }"
						@click="changeTab($event, tabData)"
					>
						{{ tabData.title }}
					</a>
					</li>
				</ul>
				</div>
        <div class="row">
          <div class="col-12 product-header sandwich-header" v-if="type == 'sandwich'">
            <section
              :id="tabData.containerName"
              v-show="isAllTabActive || tabData.isActive"
              v-for="(tabData, index) in tabWithProductData"
              :key="index"
            >
              <h2 class="category-section-title">
              {{ tabData.title }}
              </h2>
              <div class="row row-sandwich-products">
              <div
                class="col-6 col-sm-6 col-lg-4 col-xl-3 sandwich-product-header"
                v-for="(product, index) in tabData.products"
                :key="index"
              >
                <sandwich-product-detail :productDetail="product" />
              </div>
              </div>
            </section>
          </div>
      </div>
      <div class="row">
				<div class="col-12 product-header snack-header" v-if="type == 'snack'">
					<section
						:id="tabData.containerName"
						v-show="isAllTabActive || tabData.isActive"
						v-for="(tabData, index) in tabWithProductData"
						:key="index"
					>
						<h2 class="category-section-title">
							{{ tabData.title }}
						</h2>
						<div class="row row-snack-products">
							<div
								class="col-12 col-sm-6 col-lg-6 col-xl-4 snack-product-header"
								v-for="(product, index) in tabData.products"
								:key="index"
							>
								<snack-product-detail :productDetail="product" />
							</div>
						</div>
					</section>
				</div>
      </div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SandwichProductDetail from "../components/product/SandwichProductDetail.vue";
import SnackProductDetail from "../components/product/SnackProductDetail.vue";
// import sandwichEndTime from "../utilities/sandwich-end-time";
import sortArr from "../utilities/sort-array";

export default {
  name: "Products",
  components: {
    SandwichProductDetail,
    SnackProductDetail,
  },
  data() {
    return {
      tabs: [],
      type: "",
      allowedTypes: ["snack", "sandwich"],
      isAllTabActive: false,
      slickSliderSettings: {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 200,
        slidesToShow: 7,
        slidesToScroll: 2,
        variableWidth: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 5,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      },
      // selectedPickupDate: this.$store.state.selectedPickupDate,
      // selectedPickupTime: this.$store.state.selectedPickupTime,
      snacksTimings: [],
      showSandwichDeadlineMessage: false
    };
  },

  computed: {
    ...mapGetters({
      settingsId: "getSettingId",
      cartItems: "getCartItems",
      sandwichConfiguration: "getSandwichConfiguration",
      snackConfiguration: "getSnackConfiguration",
      cartHasProductType: "cartHasProductType",
      settingData: "getSettingData",
      pickupDates: "getPickupDates",
      pickupTimes: "getPickupTimes",
      // selectedPickupDate : 'getSelectedPickupDate',
      // selectedPickupTime : 'getSelectedPickupTime'
    }),
    selectedPickupDate: {
      get(){
        return this.$store.getters.getSelectedPickupDate
      },
      set(date){
        this.$store.state.selectedPickupDate = date
      } 
    },
    selectedPickupTime: {
      get(){
        return this.$store.getters.getSelectedPickupTime
      },
      set(time){
        this.$store.state.selectedPickupTime = time
      } 
    },
    tabWithProductData() {
      return this.tabs.filter((tData) => {
        if (tData.products && tData.products.length) {
          return true;
        }
        return false;
      });
    },
    // display_alert_sandwich() {
    //   var sandwich_last_time = new Date(
    //     "2020-01-01 " + this.settingData.sandwich_last_time
    //   );
    //   var selected_time = new Date("2020-01-01 " + this.selectedPickupTime);
    //   if (sandwich_last_time.getTime() < selected_time.getTime()) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    // display_alert_snacks(){
    //   return true;
    // }
  },

  mounted() {
    this.loadProducts();
    this.loadPickupDates();
    this.loadPickupTimes();
    this.loadSnackDetail();
  },
  // updated: function () {
  //   this.$nextTick(function () {
  //     if(this.display_alert_snacks){
  //       this.show_snacks_overlay();
  //     }
  //   })
  // },
  methods: {
    ...mapActions(["addItemToCart", "removeItemFromCart"]),

    hasProductInCart(productId) {
      const item = this.cartItems.find((item) => {
        return item.id === productId;
      });
      return typeof item !== "undefined" ? item : false;
    },
    messageHide(type) {
        if(type == 'sandwich'){
            document.getElementsByClassName("overlay-deadline-sandwich")[0].style.display = "none";
        }else if(type == 'snack'){
            document.getElementsByClassName("overlay-deadline-snack")[0].style.display = "none";
        }
        this.redirectForOverlay(false);
    },
    redirectForOverlay(check){
      let current_path = this.$route.path
      let current_query = this.$route.query
      current_query['overlay'] = check
      this.$router.push({path: 'temp', query: current_query});
      this.$router.push({path: current_path, query: current_query});
    },
    show_snacks_overlay() {
      this.redirectForOverlay(true);
      let ele = document.getElementsByClassName("overlay-snack")[0];
      if (ele) {
        ele.style.display = "unset";
      }
    },
    show_snacks_overlay_second() {
      document.getElementsByClassName("overlay-snack")[0].style.display = "none";
      document.getElementsByClassName("overlay-snack-second")[0].style.display = "unset";
      setTimeout(() => {
        this.hide_snacks_overlay_second();
      }, 10000);
    },
    hide_snacks_overlay_second() {
      this.redirectForOverlay(false);
      document.getElementsByClassName("overlay-snack-second")[0].style.display = "none";
      if (window.Navigation) {
        window.Navigation.postMessage("navigate-to-home");
      }
    },
    loadSnackDetail() {
      if (this.type === "snack") {
        this.$root.$load(true);
        this.$http
          .post(`snacks-order-check`, {
            setting_id: this.settingsId,
          })
          .then(() => {})
          .catch((e) => {
            if (e.response.data.code === 302) {
              this.snacksTimings = e.response.data.data;
              this.show_snacks_overlay();
            }
          })
          .finally(() => {
            this.$root.$load(false);
          });
      }
    },
    loadProducts() {
      let postData = {
        type: null,
      };
      if (
        this.$route.params &&
        this.$route.params.type &&
        this.allowedTypes.indexOf(this.$route.params.type) > -1
      ) {
        this.type = this.$route.params.type;
        postData.type = this.$route.params.type;
        if (localStorage) {
          localStorage.setItem("product_type", postData.type);
        }
      }
      this.$root.$load(true);
      const settingsId = this.settingsId;
      this.$http
        .post(`get-snack-list/${settingsId}`, postData)
        .then((response) => {
          if (response.status === 200) {
            const items = response.data.data.filter(
              (product) => !!product.category
            );

            if (
              this.cartHasProductType("sandwich") &&
              this.$route.params.type === "snack"
            ) {
              // this.$store.dispatch("removeAllSandwichItemsFromCart");
            } else if (
              this.cartHasProductType("snack") &&
              this.$route.params.type === "sandwich"
            ) {
              // this.$store.dispatch("removeAllSnackItemsFromCart");
            }
            const {
              sandwich_banner,
              sandwich_description,
              sandwich_title,
              sandwich_end_time,
              snack_banner,
              snack_description,
              snack_title,
              message_for_order_sandwich_after_deadline,
              message_for_order_snacks_after_deadline,
            } = response.data.dataset;
            this.$store.commit("SET_SANDWICH_CONFIGURATION", {
              bannerUrl: sandwich_banner,
              title: sandwich_title,
              description: sandwich_description,
              sandwichEndTime: sandwich_end_time,
              msgAfterDeadline: message_for_order_sandwich_after_deadline,
            });
            this.$store.commit("SET_SNACk_CONFIGURATION", {
              bannerUrl: snack_banner,
              title: snack_title,
              description: snack_description,
              snackMsgAfterDeadline: message_for_order_snacks_after_deadline,
            });
            let categoryData = items.map((data) => {
              return data.category;
            });
            categoryData = [
              ...new Map(
                categoryData.map((item) => [item["id"], item])
              ).values(),
            ];
            if (categoryData.length) {
              this.tabs = sortArr(categoryData);
              this.tabs = categoryData.map((data, i) => {
                return {
                  isCartItem: false,
                  containerName: data.id,
                  title: data.name,
                  order: data.order,
                  isActive: i == 0,
                  products: items.filter(
                    (pData) => pData.category.id == data.id
                  ),
                };
              });
              this.tabs = sortArr(this.tabs);
            }
            // if (this.type === "sandwich") {
            //   let result = sandwichEndTime(this.settingData.snack_last_time, this.type);
            //   if(result){
            //     // this.redirectForOverlay(true);
            //   } 
            // } else if (this.type === "snack") {
            //   let result = sandwichEndTime(this.settingData.snack_last_time, this.type);
            //   if(result){
            //     // this.redirectForOverlay(true);
            //   }
            // }
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.$root.$load(false);
        });
    },
    loadPickupDates() {
      if (this.type === "sandwich") {
        this.$root.$load(true);
        this.$http
          .post(`opening-day`, {
            setting_id: this.settingsId,
          })
          .then((response) => {
            if (response.status === 200) {
              this.$store.commit("SET_PICKUP_DATES", response.data.data);
            }
            if(response.data.dataset != ''){
              this.showSandwichDeadlineMessage = true
            }
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            this.$root.$load(false);
          });
      }
    },
    loadPickupTimes() {
      if (this.type === "sandwich") {
        this.$root.$load(true);
        this.$http
          .post(`get-slot`, {
            setting_id: this.settingsId,
          })
          .then((response) => {
            if (response.status === 200) {
              this.$store.commit("SET_PICKUP_TIMES", response.data.data.sandwich);
            }
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            this.$root.$load(false);
          });
      }
    },
    changeTab(e, tabData) {
      e.preventDefault();

      this.isAllTabActive = false;

      if (tabData.isActive) {
        this.isAllTabActive = true;
      }

      tabData.isActive = !tabData.isActive;

      this.tabs = this.tabs.map((data) => {
        if (data.containerName != tabData.containerName) {
          data.isActive = false;
        }
        return data;
      });
    },
    addToCart(product, qty) {
      product.quantity = (product.quantity || 0) + qty;
      if (product.quantity > 0) {
        this.addItemToCart(product);

        if (!product.isProductExistInCart) {
          this.$toast.open({
            message: this.$i18n.t("Product.TOAST_MESSAGE.SUCCESS_ADD_TO_CART"),
            type: "success",
          });
        }

        product.isProductExistInCart = true;
      } else {
        product.quantity = 0;
        this.removeItemFromCart(product);
        product.isProductExistInCart = false;
      }

      setTimeout(() => {
        this.$forceUpdate();
      }, 100);
    },
  },
};
</script>

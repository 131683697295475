<script>
  import { mapActions, mapGetters } from 'vuex'
  import parsePrice from '../../utilities/parse-price'
  import arraysEqual from "../../utilities/arrays-equal"


  export default {
    name: 'BaseProductItem',

    props: {
      productDetail: {
        type: Object,
        default: () => {}
      }
    },

    data: () => {
      return {
        checkedValue: false,
        product: {},
        isProductExistInCart: false
      }
    },

    computed: {
      ...mapGetters({ cartItems: 'getCartItems' }),

      productPrice () {
        return parsePrice(this.product.price);
      },

      existCartQuantity () {
        const existingProduct = this.cartItems.find( (prdct) => { 
          if ( this.product.type === 'snack') {
            const alreadySelectedAdjustment = prdct.adjustment.filter(adjustment => adjustment.selected)
              .map(adjustment => adjustment.id).sort()

            const newlySelectedAdjustment = this.product.adjustment.filter(adjustment => adjustment.selected)
              .map(adjustment => adjustment.id).sort()

            const passCheck = arraysEqual(alreadySelectedAdjustment, newlySelectedAdjustment)
            return prdct.id ===  this.product.id && passCheck 
          }

          return prdct.id ===  this.product.id
        }) 

      if (!existingProduct) {
        return 0
      }

      return existingProduct.quantity;
      },
    },

    created () {
      this.checkProductOnCart()
    },

    methods: {
      ...mapActions([
        'addItemToCart',
        'removeItemFromCart'
      ]),

      addToCart () {
        if (this.product.quantity > 0) {
          this.addItemToCart(this.product)
          this.isProductExistInCart = true
        } else {
          this.removeItemFromCart(this.product)
          this.isProductExistInCart = false
        }
      },

      animate () {
        const rect = this.$el.getBoundingClientRect();
        const element = this.$el.cloneNode(true);
        const animationDuration = 400;

        element.style.height = `${rect.height}px`;
        element.style.width = `${rect.width}px`;
        element.style.position = `fixed`;
        element.style.top = `${rect.top}px`;
        element.style.left = `${rect.left}px`;
        element.style.minHeight = `auto`;

        element.style.animation = `animate-position ease-in ${animationDuration}ms`;

        const productContent = element.querySelector('.product-content');
        if (productContent) {
          productContent.style.flexGrow = '0';
        }

        element.classList.add('transition-to-cart');

        const top = rect.top;
        const left = rect.left;

        const toTop = 0 - (rect.height * 2);
        const toLeft = window.innerWidth + rect.width;

        const style = document.createElement('style');
        style.type = 'text/css';
        const keyframes = `
                    @keyframes animate-position {
                      from {
                        top: ${top}px;
                        left: ${left}px;
                        opacity: 1;
                        transform: scale(1.0);
                      }
                      to {
                        top: ${toTop}px;
                        left: ${toLeft}px;
                        opacity: 0;
                        transform: scale(0.1);
                      }
                    }

                    @-moz-keyframes animate-position {
                      from {
                        top: ${top}px;
                        left: ${left}px;
                        opacity: 1;
                        transform: scale(1.0);
                      }
                      to {
                        top: ${toTop}px;
                        left: ${toLeft}px;
                        opacity: 0;
                        transform: scale(0.1);
                      }
                    }

                    @-webkit-keyframes animate-position {
                      from {
                        top: ${top}px;
                        left: ${left}px;
                        opacity: 1;
                        transform: scale(1.0);
                      }
                      to {
                        top: ${toTop}px;
                        left: ${toLeft}px;
                        opacity: 0;
                        transform: scale(0.1);
                      }
                    }
                    `;

        style.innerHTML = keyframes;
        style.id = 'articleTransitionStyles';

        document.body.appendChild(element);
        document.getElementsByTagName('head')[0].appendChild(style);

        setTimeout(() => {
          element.remove();
          style.remove();
        }, animationDuration - 1);
      },

      updateQuantityValue (q) {
        this.quantityNumber += q;
        if ( this.quantityNumber <= 0 ) {
          this.quantityNumber = 1;
        }
      },

      updateQuantity (q) {
        const lastQuantity = this.product.quantity
        if ( this.product.type === 'snack') {
            this.product.quantity = this.quantityNumber + this.existCartQuantity;
            this.quantityNumber = 1;
            if ( this.product.quantity > 0) {
              this.animate()
              this.$nextTick( () => {
                this.product.adjustment.forEach(adjustment => {
                  this.$set(adjustment, 'selected', false)
                })
              })
            }
        } else {
          this.product.quantity += q
          if( this.product.quantity <= 0 ) {
            this.product.quantity = 0;
          }

          if (this.product.quantity > lastQuantity) {
            this.animate()
          }
        }
          this.addToCart()

        if (this.product.type === 'snack') {
          this.product.quantity = 0;
        }
      },

      checkProductOnCart () {
        let quantity = 0;
        let is_expand = false;
        const existCartItem = this.hasProductInCart(this.productDetail.id)

        if (existCartItem) {
          quantity = existCartItem.quantity
          this.isProductExistInCart = true
        }

        this.product = Object.assign({}, { ...this.productDetail, quantity, is_expand })

        if (this.product.type === 'snack') {
          this.product.adjustment.forEach(adjustment => {
            this.$set(adjustment, 'selected', false)
          })
        }
      },

      hasProductInCart (productId) {
        const item = this.cartItems.find((item) => { return item.id === productId })
        return typeof item !== 'undefined' ? item : false
      },
      convertPrice ( price ) {
        price = price.replace(',', '.');
        return price;
      },
      getProductTotal (){
        let price = this.product.price.replace( '€', '').trim();

        price = this.convertPrice(price);
        if( this.product.quantity && this.product.quantity > 0 ){
          price = ( this.product.quantity * price );
        }
        price = parseFloat( price ) .toFixed(2);
        return price;
      }
    }
  }
</script>

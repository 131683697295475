<template>

  <div class="snack-list-container">
    <a :class="{  'collapsed': !product.is_expand}" data-toggle="collapse" href="#" role="button" aria-expanded="false" @click="productDetailOpenClose( $event, product )">
        <div class="snack-top-container">
            <div class="sanck-img-box">
                <img :src="product.image">
            </div>
            <div class="snack-content-box">
                <h3 class="snack-title">
                    {{product.title}}
                </h3>
                <p class="snack-details" v-if="this.product.sub_title">
                    {{product.sub_title}} 
                </p>
                <p class="snack-price">
                    {{ calculatedPrice | tenzerCurrency }}
                </p>
                <div class="snack-button">
                    <img src="/images/plus.svg" class="snack-plus-btn" >
                    <img src="/images/minus.svg" class="snack-minus-btn">
                </div>
            </div>
        </div>
    </a>
    <div class="row">
        <div class="col-12">
            <div class="snack-bottom-container collapse" :class="{ 'show': product.is_expand , 'collapsed': !product.is_expand}">
                <form>
                    <div ref="adjustmentsTable" class="snack-table-container">
                        <div class="snack-adjustment" v-for="( opt, index ) in customizeOptions" :key="index">
                            <div class="snack-adjustment-input">
                                <div class="custom-control custom-checkbox child1">
                                    <input type="checkbox" class="custom-control-input" :id="'customCheck'+ index + product.id" v-model="opt.selected" :value="true">
                                    <label class="custom-control-label checkbox-label" :for="'customCheck'+ index + product.id">{{opt.title}}</label>
                                </div>
                            </div>
                            <div class='snack-adjustment-price text-right'>
                                <div class="">
                                    + {{opt.price | tenzerCurrency}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="adjustment-plusicon" :class="{'adjustments-collapsed': collapseAdjustment}" ref="plusIcon"  v-if="customizeOptions.length > 3">  
                        <a
                            href="#"
                            @click.prevent="toggleCollapse"
                        >
                            <span class="bekijk" v-if="!collapseAdjustment"> {{ $t('Product.SNACK.SHOW_ALL') }} <span class="arrow"><i class="fas fa-chevron-down"></i></span> </span>
                            <span class="bekijk" v-if="collapseAdjustment"> {{ $t('Product.SNACK.SHOW_LESS') }} <span class="arrow"><i class="fas fa-chevron-down"></i></span> </span>
                        </a>
                    </div>
                    <div class="snack-bottom-price-container">
                        <div class="snack-prd-count-container">
                            <button type="button" class="prd-count-btn button-element" @click="updateQuantityValue( -1 )"><i class="fas fa-minus"></i></button>
                            <input class="prd-count-number" placeholder="" :value="quantityNumber" :disabled="true">
                            <button type="button" class="prd-count-btn button-element" @click="updateQuantityValue( 1 )"><i class="fas fa-plus"></i></button>
                        </div>
                        <div>
                            <button type="button" class="btn btn-theme button-element" @click="updateQuantity( 1 )"> {{$t('Product.SNACK.ADD_TO_CART')}} </button>
                        </div>
                    </div>  
                </form>
            </div>     
        </div>
    </div>
</div>
</template>

<script>
  import BaseProductItem from './BaseProductItem.vue'

  export default {
    name: 'SnackProductDetail',

    extends: BaseProductItem,

    data() {
        const selectedAddOns = {}
        
        this.productDetail.adjustment.forEach((adjustment) => {
            selectedAddOns[adjustment.id] = false
        })

      return {
        selectedAddOns,
        collapseAdjustment: false,
        quantityNumber: 1,
      }
    },

    computed: {
      customizeOptions () {
          return this.productDetail.adjustment;
      },

      calculatedPrice () {
        return this.productPrice + this.customizeOptions.reduce((total, option) => {
            return total + (option.selected ? option.price : 0)
        }, 0);
      }
    },

    watch: {
        collapseAdjustment(open) {
            this.$refs.adjustmentsTable.style.maxHeight = open ? `${this.$refs.adjustmentsTable.scrollHeight}px` : '';
        }
    },

    methods: {
       productDetailOpenClose( e, product ){
        e.preventDefault();
        this.$nextTick( () => {

          product.is_expand = !product.is_expand;
          this.$forceUpdate();
        })
      },
      toggleCollapse () {
        this.collapseAdjustment = !this.collapseAdjustment;
      }
    }
  }
</script>

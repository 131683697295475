<template>

  <div class="product-container">
      <div class="product-img-container">
          <img :src="product.image" :alt="product.title">
      </div>
      <div class="product-content">
          <h3 class="product-name">
              <a href="#">{{product.title}}</a>
          </h3>
          <div class="prd-count-container">
              <p class="produst-price">
                  {{productPrice | tenzerCurrency }}
              </p>
              <button class="button-element prd-count-btn" @click="updateQuantity( -1 )" :disabled="product.quantity <= 0"><i class="fas fa-minus"></i></button>
              <input class="prd-count-number" :placeholder="product.quantity > 0 ? product.quantity:''" :value="product.quantity" :disabled="true">
              <button class="button-element prd-count-btn" @click="updateQuantity( 1 )"><i class="fas fa-plus"></i></button>

          </div>
      </div>
  </div>
</template>

<script>
  import BaseProductItem from './BaseProductItem.vue'

  export default {
    name: 'SandwichProductDetail',

    extends: BaseProductItem
  }
</script>
